<template>
  <header v-if="!unInterruptedView"
    class="is-header-fixed-top">
    <component :is="randomiseSpinner"
      v-show="showSpinnerRequestCount > 0"
      :message="spinnerMessage" />
    <nav class="navbar"
      v-if="$userInfo">
      <div class="navbar-brand">
        <a class="navbar-item is-active py-0 pr-1 pl-1"
          @click="gotoRoute(rootUrl)">
          <img src="@/assets/images/flexiquote-logo.svg"
            style="max-height: 2.25rem"
            alt=""
            height="40"
            title="Home">
        </a>
        <a class="navbar-item is-hidden-desktop tooltip is-tooltip-right"
          v-if="$userInfo.isSupportUser"
          target="_blank"
          :href="pollingLogUrl"
          title="Polling Logs"
          data-tooltip="Polling Logs">
          <span class="icon"
            style="color: #DC7633;">
            <i class="mdi mdi-file-document" />
          </span>
        </a>
        <a class="navbar-item is-hidden-desktop tooltip is-tooltip-right"
          v-if="$userInfo.isSupportUser"
          target="_blank"
          :href="viewErrorUrl"
          title="View Errors"
          data-tooltip="View Errors">
          <span class="icon"
            style="color: #CD6155;">
            <i class="mdi mdi-alert" />
          </span>
        </a>
        <a class="navbar-item is-hidden-desktop tooltip is-tooltip-right"
          @click="signOut"
          title="Sign Out"
          data-tooltip="Sign Out">
          <span class="icon"
            style="color: #9B59B6;">
            <i class="mdi mdi-logout" />
          </span>
        </a>
        <div class="navbar-burger burger"
          data-target="main-nav-menu"
          @click="toogleBurgerMenu()">
          <span />
          <span />
          <span />
        </div>
      </div>
      <div id="main-nav-menu"
        class="navbar-menu"
        :class="{ 'is-active': burgerMenuActive}">
        <div class="navbar-start">
          <div class="navbar-item has-dropdown is-hoverable"
            v-for="i in nav"
            :key="i.name">
            <a class="navbar-link"
              @click="go(i)">
              {{ i.name }}
            </a>
            <div class="navbar-dropdown is-boxed">
              <div v-for="(i2, idx2) in i.items"
                :key="i2.name">
                <hr v-if="i2.items.length > 0 && i.items[idx2-1] && i.items[idx2-1].items.length == 0"
                  class="navbar-divider">
                <div class="submenu-title"
                  v-if="i2.items.length > 0">
                  <span class="title is-6 has-text-primary">{{ i2.name }}</span>
                </div>
                <a v-if="i2.items.length == 0"
                  class="navbar-item"
                  :class="{ 'is-active': i2.code === selectedMenuCode }"
                  @click="go(i2, $event)"
                  @click.middle="goNewTab(i2)">
                  {{ i2.name }}
                </a>
                <a v-else
                  v-for="i3 in i2.items"
                  :key="i3.name"
                  class="navbar-item"
                  :class="{ 'is-active': i3.code === selectedMenuCode }"
                  @click="go(i3, $event)"
                  @click.middle="goNewTab(i3)">
                  {{ i3.name }}
                </a>
                <hr v-if="i2.items.length > 0"
                  class="navbar-divider">
              </div>
            </div>

          </div>

        </div>
        <div class="navbar-end">
          <div class="navbar-item py-0">
            <vue-clock />
          </div>
          <div class="navbar-item">
            <notification />
          </div>
          <div v-if="false"
            class="navbar-item">
            <agent />
          </div>
          <div class="navbar-item">
            <div class="field is-grouped">
              <!-- <notification /> -->
              <!-- <div class="control notification-message"
                @mouseleave="showNotification = false">
                <a class="button is-info"
                  :data-badge="notificationCount > 0 ? notificationCount : null"
                  :class="[{ 'has-badge-danger has-badge-rounded has-badge-small': notificationCount > 0 }]"
                  @click="toggleNotification($event)">
                  <span class="icon is-small">
                    <i class="mdi mdi-bell mdi-24px" />
                  </span>
                </a>
                <div v-if="showNotification"
                  class="notification-message-popup animated bounceIn"
                  style="animation-duration: 0.5s">
                  <article class="message is-danger">
                    <div class="message-header">
                      <p>Notifications</p>
                      <button class="delete"
                        @click="toggleNotification()" />
                    </div>
                    <div class="message-body">
                      <expiry-message :licence-data="licenceData" />
                    </div>
                  </article>
                </div>
              </div> -->
              <div class="dropdown is-right"
                :class="{ 'is-active': companyDropdownActive }"
                @mouseleave="companyDropdownActive = false">
                <div class="dropdown-trigger">
                  <a class="button is-info"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    @click="toogleDropdown()">
                    <span>{{ $userInfo.companyName }}</span>
                    <span class="icon is-small">
                      <i class="mdi mdi-chevron-down mdi-18px" />
                    </span>
                  </a>
                </div>
                <!-- <transition mode="in-out" appear name="custom-classes-transition" appear-active-class="animated bounceIn" enter-active-class="animated bounceIn" leave-active-class="animated bounceInOut"> -->
                <!-- <div class="dropdown-menu animated bounceIn"
                  style="animation-duration: 0.5s"> -->
                <div class="dropdown-menu"
                  role="menu">
                  <div class="dropdown-content">
                    <div class="dropdown-item dropdown-item-has-icon">
                      <span class="icon"
                        style="color: #5D6D7E;">
                        <i class="mdi mdi-account mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">{{ $userInfo.displayName }}</span>
                    </div>
                    <hr class="dropdown-divider">
                    <a class="dropdown-item dropdown-item-has-icon"
                      v-if="$userInfo.isSupportUser"
                      target="_blank"
                      :href="pollingLogUrl"
                      @click="companyDropdownActive = false">
                      <span class="icon"
                        style="color: #DC7633;">
                        <i class="mdi mdi-file-document mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Polling Log</span>
                    </a>
                    <a class="dropdown-item dropdown-item-has-icon"
                      v-if="$userInfo.isSupportUser"
                      @click="gotoRoute(companyOnboardingUrl)"
                      @click.middle="gotoRoute(companyOnboardingUrl, true)">
                      <span class="icon has-text-success">
                        <i class="mdi mdi-office-building-cog mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Company Onboarding</span>
                    </a>
                    <a class="dropdown-item dropdown-item-has-icon"
                      v-if="$userInfo.isSupportUser"
                      target="_blank"
                      :href="viewErrorUrl"
                      @click="companyDropdownActive = false">
                      <span class="icon has-text-danger">
                        <i class="mdi mdi-alert mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">View Error</span>
                    </a>
                    <a class="dropdown-item dropdown-item-has-icon"
                      href="#"
                      @click="signOut">
                      <span class="icon"
                        style="color: #9B59B6;">
                        <i class="mdi mdi-logout mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">Sign Out</span>
                    </a>
                    <hr class="dropdown-divider">
                    <a class="dropdown-item dropdown-item-has-icon"
                      href="#"
                      @click="openAboutModal()">
                      <span class="icon has-text-primary">
                        <i class="mdi mdi-information-variant mdi-18px" />
                      </span>
                      <span class="dropdown-item-text">About</span>
                    </a>
                  </div>
                </div>
                <!-- </transition> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <about-modal :active.sync="isAboutModalActive"
      :expiry-date="expiryDate"
      @close="closeModal()" />
  </header>
</template>
<script>
import Auth from '@/auth'
import NavService from '@/components/NavService'
import { mapGetters, mapActions } from 'vuex'
import { Circle9, DoubleBounce, LetterCube, Circle8, Socket, Spinner, RandomSpinner } from '@/components/Spinners'
import ExpiryMessage from '@/components/ExpiryMessage'
import { AboutModal } from '@/components/BulmaModal'
import { EventHubTypes } from '@/enums'
import UtilitiesRoutes from '@/views/utilities/route-types'
import VueClock from '@/components/VueClock/VueClock'
import Notification from '@/components/Notification/Notification'
import Agent from '@/components/Agent/Agent'
import CompanyRoutes from '@/views/company/route-types'

export default {
  components: {
    ExpiryMessage,
    Circle9,
    DoubleBounce,
    LetterCube,
    Circle8,
    Socket,
    Spinner,
    AboutModal,
    VueClock,
    Notification,
    Agent
  },
  data() {
    return {
      auth: this.$store.state.auth,
      nav: null,
      isLoading: true,
      isLoadingClass: 'is-loading',
      notificationCount: 0,
      showNotification: false,
      licenceData: null,
      burgerMenuActive: false,
      companyDropdownActive: false,
      selectedMenuCode: '',
      isAboutModalActive: false,
      unInterruptedView: false
    }
  },
  computed: {
    rootUrl: function () {
      return '/'
    },
    pnetUrl: function () {
      return {
        path: UtilitiesRoutes.PnetUtilitiesDetail.path,
        params: {}
      }
    },
    ormUrl: function () {
      return {
        path: UtilitiesRoutes.OrmUtilitiesDetail.path,
        params: {}
      }
    },
    pollingLogUrl: function () {
      return process.env.VUE_APP_ROOT_URI + '/Modules/EstimatesView/PollingLog.aspx'
    },
    viewErrorUrl: function () {
      return process.env.VUE_APP_ROOT_URI + '/Error/ErrorDetailView.aspx'
    },
    companyRoutes() {
      return CompanyRoutes
    },
    companyOnboardingUrl() {
      return CompanyRoutes.CompanyOnboardingView.path
    },
    buttonCSS: function () {
      return `${this.isLoadingClass}`
    },
    expiryDate: function () {
      if (this.licenceData) {
        const localDate = new Date(this.licenceData.expiryDate)
        if (Intl) {
          return Intl.DateTimeFormat(this.$userInfo.locale).format(localDate)
        } else {
          return localDate.toLocaleDateString(this.$userInfo.locale)
        }
      } else {
        return ''
      }
    },
    ...mapGetters(['licenceExpired', 'showSpinnerRequestCount', 'spinnerMessage']),
    randomiseSpinner() {
      return RandomSpinner
    }
  },
  watch: {
    $route: function () {
      this.changeTitle()

      if (!!this.$route.query.uninterruptedview) {
        this.unInterruptedView = true
      }
    }
  },
  created() {
    this.$eventHub.$on(EventHubTypes.SetActiveMenuCode, (code) => {
      this.selectedMenuCode = code
    })
  },
  mounted: async function () {
    this.$showSpinner()
    // try {
    // Nav bar
    this.nav = await NavService.getNav()

    const licenceResult = await NavService.getLicenceData()

    // Global user data
    this.licenceData = licenceResult
    this.isLoadingClass = 'is-inverted'

    // Licence data
    if (this.licenceData.isExpired || this.licenceData.isExpiring) {
      this.notificationCount++
      this.updateLicenceExpired(this.licenceData.isExpired && !this.licenceData.isGracePeriod)
    } else {
      if (this.notificationCount > 0) {
        this.notificationCount--
      } else {
        this.notificationCount = 0
      }
      this.updateLicenceExpired(false)
    }
    // } catch (err) {
    //   // temp solution
    //   // either one of the promises failure will cause spinner to spin infinitely
    //   // trying to show fatal errors with another dialogue
    //   console.log(err)
    // }
    this.$hideSpinner()
    this.changeTitle()
  },
  methods: {
    go(item, event) {
      if (item.url && item.url !== 'null') {
        if (item.url.indexOf('~') > -1) {
          this.$showSpinner()
          const url = item.url.replace('~', process.env.VUE_APP_ROOT_URI)
          window.location.href = url
        } else {
          this.$router.push(item.url.charAt(0) === '/' ? item.url : `/${item.url}`)
        }
      }
      return false
    },
    getUrl(item) {
      if (item.url && item.url !== 'null') {
        if (item.url.indexOf('~') > -1) {
          const url = item.url.replace('~', process.env.VUE_APP_ROOT_URI)
          return url
        } else {
          const itemUrl = item.url.charAt(0) === '/' ? item.url.slice(1) : item.url
          // const url = process.env.VUE_APP_BASE_URI.charAt(0) === '/' ? `${process.env.VUE_APP_BASE_URI}${itemUrl}` : `/${process.env.VUE_APP_BASE_URI}${itemUrl}`
          const url = process.env.BASE_URL.charAt(0) === '/' ? `${process.env.BASE_URL}${itemUrl}` : `/${process.env.BASE_URL}${itemUrl}`
          return url
        }
      }
      return ''
    },
    goNewTab(item) {
      const url = this.getUrl(item)
      if (url) {
        window.open(url, '_blank')
      }
    },
    toogleBurgerMenu() {
      this.burgerMenuActive = !this.burgerMenuActive
    },
    logout() {
      Auth.logout()
    },
    toggleNotification(event) {
      if (this.notificationCount > 0) {
        this.showNotification = !this.showNotification
      }
    },
    removeAllSessionObjects() {
      Object.keys(sessionStorage)
        // .filter(function(k) { return /foo/.test(k) })
        .forEach(function (k) {
          sessionStorage.removeItem(k)
        })
    },
    signOut() {
      this.$showSpinner()
      this.companyDropdownActive = false
      NavService.signOut()
        .then((result) => {
          if (result.data) {
            // this.removeAllSessionObjects()
            window.location.href = process.env.VUE_APP_ROOT_URI + (this.$userInfo.isSupportUser ? '/SignInCsr.aspx' : '/SignIn.aspx')
          }
        })
        .catch(function (error) {
          this.$hideSpinner()
          console.error(error)
        })
    },
    toogleDropdown() {
      this.companyDropdownActive = !this.companyDropdownActive
    },
    openAboutModal() {
      this.companyDropdownActive = false
      this.isAboutModalActive = true
    },
    closeModal() {
      this.isAboutModalActive = false
    },
    ...mapActions(['updateLicenceExpired']),
    gotoRoute(route, newTab = false) {
      if (!newTab) {
        this.$router.push(route)
      } else {
        window.open(route, '_blank')
      }
      this.selectedMenuCode = ''
    },
    changeTitle() {
      const routes = this.$route.matched.slice()
      var title = ''
      routes.forEach((r, idx) => {
        if (idx > 0) {
          title += ' '
        }
        title += r.meta.title
      })
      document.title = title // parentRoute.meta.title
    }
  }
}
</script>
<style lang="scss" scoped>
.submenu-title {
  padding: 0.2rem 0.5rem; // > span {
  //   font-size: 1rem;
  //   font-weight: 600;
  // }
}

.navbar {
  z-index: 32;
}

.notification-message {
  position: relative !important;
  display: inline-block !important;
}

.notification-message-popup {
  right: 0;
  display: block;
  position: absolute;
  min-width: 480px;
  z-index: 1000;
}
div.dropdown-item-has-icon {
  display: flex;
  align-items: center;
}
a.dropdown-item-has-icon {
  display: flex;
  align-items: center;
}
span.dropdown-item-text {
  padding-left: 5px;
}
</style>
