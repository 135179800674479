import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
import ProtoBufApi from '@/services/protobuf-api'

const baseUrl = '/quotes'

export default {
  async getEntity(id, hash) {
    const url = !hash ? `${baseUrl}/${id}` : `${baseUrl}/${id}?hash=${hash}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialiseQuoteFilter(filter)
    const url = `${baseUrl}/list?filter=${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseQuoteFilter(filter) {
    const entityProto = require('../assets/proto/quote/QuoteFilter.proto')
    const filterMessage = entityProto.QuoteFilter.QuoteFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getImageIds(quoteId) {
    const url = `${baseUrl}/images/ids/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getImageThumbnailWithInfos(quoteId) {
    const url = `${baseUrl}/images/thumbnailinfos/ids/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getImageThumbnailWithInfo(id, size) {
    const url = `${baseUrl}/images/thumbnailinfo/${id}?size=${size}`
    const result = await FqApi.get(url)
    result.data.imageContent = `data:${result.data.mimeType};base64,${result.data.imageContent}`
    return result.data
  },
  async getImagesThumbnailWithInfo(id, size) {
    const url = `${baseUrl}/images/thumbnailinfo/${id}?size=${size}`
    const result = await FqApi.get(url)
    result.data.imageContent = `data:${result.data.mimeType};base64,${result.data.imageContent}`
    return result.data
  },
  async updateImageStatuses(imageMetas) {
    const url = `${baseUrl}/images/updatestatuses`
    const result = await FqApi.post(url, imageMetas)
    return result
  },
  async getQuoters() {
    const url = `${baseUrl}/quoters`
    const result = await FqApi.get(url)
    return result.data
  },
  async getInsurerDropdownProto(filter) {
    const serialised = this.serialiseQuickInvoiceAssetDropdownFilter(filter)
    const result = await ProtoBufApi(`/quickinvoices/protobuf/assets/listcombo/${serialised}`)
    const proto = require('@/assets/proto/quickinvoice/QuickInvoiceAssetDropdown.proto')
    const message = proto.QuickInvoiceAssetDropdown.QuickInvoiceAssetDropdownModel
    const entity = message.decode(new Uint8Array(result.data))
    return entity.assets
  },
  serialiseQuickInvoiceAssetDropdownFilter(filter) {
    const entityProto = require('@/assets/proto/quickinvoice/QuickInvoiceAssetDropdownFilter.proto')
    const filterMessage = entityProto.QuickInvoiceAssetDropdownFilter.QuickInvoiceAssetDropdownFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  }
}