// Modal componenet based on Buefy Modal from https://github.com/rafaelpimpa/buefy
<template>
  <transition
    name="custom-classes-transition"
    :enter-active-class="`animate__animated animate__${animationIn}`"
    :leave-active-class="`animate__animated animate__${animationOut}`">
    <div
      class="modal is-active"
      v-if="isActive"
      :style="`animation-duration: ${animationDuration}s`">
      <div
        class="modal-background"
        @click="cancel" />
      <div
        :class="{ 'modal-content': !hasModalCard, 'modal-card': hasModalCard }"
        :style="{ 'min-width': `${minWidth}vw`, 'min-height' : `${minHeight}vh` }">
        <component
          v-if="component"
          v-bind="props"
          :is="component"
          @close="close" />
        <div
          v-else-if="content"
          v-html="content" />
        <slot v-else />
      </div>
      <button
        v-if="canCancel"
        class="modal-close is-large"
        @click="cancel" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BulmaModal',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    component: Object,
    content: String,
    programmatic: Boolean,
    props: Object,
    minWidth: {
      type: [String, Number],
      default: 30
    },
    minHeight: {
      type: [String, Number],
      default: 30
    },
    hasModalCard: Boolean,
    canCancel: {
      type: Boolean,
      default: true
    },
    onCancel: {
      type: Function,
      default: () => {}
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    }
  },
  data() {
    return {
      isActive: this.active || false,
      newWidth: typeof this.width === 'number' ? this.width + 'px' : this.width
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
    isActive() {
      if (typeof window !== 'undefined') {
        const action = this.isActive ? 'add' : 'remove'
        document.documentElement.classList[action]('is-clipped')
      }
    }
  },
  created() {
    if (typeof window !== 'undefined') {
      document.addEventListener('keyup', this.keyPress)
    }
  },
  beforeMount() {
    // Insert the Modal component in body tag
    // only if it's programmatic
    this.programmatic && document.body.appendChild(this.$el)
  },
  mounted() {
    if (this.programmatic) this.isActive = true
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('keyup', this.keyPress)
    }
  },
  methods: {
    /**
     * Close the Modal if canCancel.
     */
    cancel() {
      if (!this.canCancel) return
      this.close()
    },
    /**
     * Call the onCancel prop (function).
     * Emit events, and destroy modal if it's programmatic.
     */
    close() {
      this.onCancel.apply(null, arguments)
      this.$emit('close')
      this.$emit('update:active', false)
      // Timeout for the animation complete before destroying
      if (this.programmatic) {
        this.isActive = false
        setTimeout(() => {
          this.$destroy()
          this.$el.remove()
        }, 150)
      }
    },
    /**
     * Keypress event that is bound to the document.
     */
    keyPress(event) {
      // Esc key
      if (event.keyCode === 27) this.cancel()
    }
  }
}
</script>
