import Guid from '@/components/Guid'

export default class CompanyMonthlySettingModel {
  constructor(companyId, fiscalYearName, year = 0, month = 0) {
    this.companyId = companyId
    this.companyMonthlySettingId = Guid.newGuid()
    this.fiscalYearName = fiscalYearName
    this.year = year
    this.month = month
    this.monthlyTarget = 0
    this.workingDays = 0
    this.isDeleted = false
    this.isNew = true
  }
}