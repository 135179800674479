import FqApi from '@/services/fq-api'
import { NotificationType } from '@/enums'

export default {
  async getDashboardJobStatus(){
    const url = '/companies/dashboards/status'
    const {data} = await FqApi.get(url)
    return data
  },

  async disableUninvoicedJobsAndJobChartStatus(){
    const url = '/companies/dashboards/hideComponent'
    const {data} = await FqApi.get(url)
    return data
  },

  async getDashboardPnetStatus(){
    const url = '/companies/dashboards/pnet'
    const {data} = await FqApi.get(url)
    return data
  },

  async getDashboardOrmStatus(){
    const url = '/companies/dashboards/orm'
    const {data} = await FqApi.get(url)
    return data
  },

  async getDashboardIncomingMessages(){
    const url = '/companies/dashboards/message'
    const {data} = await FqApi.get(url)
    return data
  },
  async getDashboardUnInvoicedJobs(){
    const url = '/companies/dashboards/uninvoicedjob'
    const {data} = await FqApi.get(url)
    return data
  },
  async getDashboardJcni(type){
    const url = `/companies/dashboards/jcni/${type}`
    const {data} = await FqApi.get(url)
    return data
  },
  async getDashboardJobsChartData(){
    const url = '/companies/dashboards/jobschart'
    const {data} = await FqApi.get(url)
    return data
  },

  // id : message id, type : Guid
  async setMessageStatus(id, notificationType){
    let url = ''
    if (notificationType === NotificationType.QuoteSmsIncoming) {
      url = `/sms/messages/${id}/status`
    } else if (notificationType === NotificationType.QuoteWhatsAppIncoming) {
      url = `/whatsapp/messages/${id}/status`
    } else if (notificationType === NotificationType.QuoteEmailIncoming) {
      url = `/emails/messages/${id}/status`
    }
    const {data} = await FqApi.put(url)
    return data
}
}