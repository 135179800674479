import Guid from '@/components/Guid'
import { AssetTypes } from '@/enums'

export class Emailer {
  constructor(toEmails, ccEmails, bccEmails, fromEmail, subject, message, reportName, attachments, assetId, remark, remarkTypeId, assetType) {
    this.id = Guid.newGuid()
    this.toEmails = toEmails || []
    this.ccEmails = ccEmails || []
    this.bccEmails = bccEmails || []
    this.fromEmail = fromEmail || ''
    this.subject = subject || ''
    this.message = message || ''
    this.reportName = reportName || ''
    this.attachments = attachments || []
    // Properties to create remarks if emailing report
    this.assetId = assetId || Guid.empty()
    //Quote
    this.assetType = assetType || ''
    this.assetTypes = [AssetTypes.Customer, AssetTypes.Insurer, AssetTypes.Vendor, AssetTypes.Contact]
    this.remark = remark || ''
    this.remarkTypeId = remarkTypeId || 0
  }
}
