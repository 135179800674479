export default {

  bind(el, binding) {
    el.addEventListener('keydown', function (e) {
      if (e.key === 'Tab') {
        e.preventDefault()
        // get parent with class quote-builder-item-row
        const parent = el.closest('.quote-builder-item-row')

        // get all focusable elements inside the parent exclude class .skip-tab
        const focusableElements = Array.from(parent.querySelectorAll('input:not(.skip-tab), textarea:not(.skip-tab), select:not(.skip-tab)'))
        .filter(
          (el) => !el.disabled && !el.hidden
        )
        const currentElementIndex = focusableElements.indexOf(e.target)
        const nextElement = focusableElements[currentElementIndex + 1]
        if (nextElement) {
          nextElement.focus()
        } else {
          // if there is no next element, then emit the event to focus the next row
          binding.value()
          // console.log('focus next row')
        }
      }
    })
  }
}