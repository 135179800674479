import JobInvoicedRoutes from './route-types'

const JobInvoicedView = r => require.ensure([], () => r(require('./JobInvoicedView.vue')), 'jobinvoicedlist-chunk')

const moduleId = 'A180'

export default [
  {
    path: JobInvoicedRoutes.JobInvoicedView.path,
    name: JobInvoicedRoutes.JobInvoicedView.name,
    component: JobInvoicedView,
    meta: {
      id: moduleId,
      route: 'listing',
      title: 'Job Invoiced',
      fkey: 'jobinvoiced|filter',
      report: 'rptJobInvoiced',
      reportUrl: `${process.env.VUE_APP_ROOT_RUI}/modules/reports/printpreview.aspx`
    }
  }
]
