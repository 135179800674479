function formatPascalToDescription(value) {
  return value
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
    .replace(/^./, function (str) {
      return str.toUpperCase()
    })
}

function truncate(str, maxLength) {
  if (!str) return ''
  if (!maxLength || !Number.isInteger(maxLength))
    maxLength = 100
  if (str.length > maxLength)
    return str.substring(0, maxLength) + '...'
  return str
}

export {
  formatPascalToDescription,
  truncate
}
